import type { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'
import { getCurrentTime } from '@/utils/getCurrentTime'
import { useUserStore } from '@/store/user'
import { useDebugStore } from '@/store/debug'

export const baseURL = `${import.meta.env.VITE_APP_API_URL}`
export const AppVersion = '1.4.7'

const axiosInstance = axios.create({
  baseURL,
  timeout: 40 * 1000,
})

interface AxiosConfig extends AxiosRequestConfig {
  ignoreAuth?: boolean
}

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig & AxiosConfig) => {
  const userStore = useUserStore()
  if (userStore.isLoggedIn && userStore.accessToken !== '' && !config?.ignoreAuth) {
    config.headers.Authorization = `Bearer ${userStore.accessToken}`
  } else if (userStore.isGetTokenFromNative.isLoaded && userStore.isGetTokenFromNative.value !== '' && !config?.ignoreAuth) {
    config.headers.Authorization = `Bearer ${userStore.isGetTokenFromNative.value}`
  }

  // TODO: Get the APP version from Capacitor App Plugin
  config.headers['App-Version'] = AppVersion
  return config
})

async function handleError(error: AxiosError) {
  const data: any = error.response?.data
  const errorMessage = data
    ? (data.message ?? data.errorMessage ?? data.detail)
    : error.message

  const userStore = useUserStore()
  const response: any = error.response
  if (data?.code === 403) {
    // 如果是因为refreshToken进入这个地方，就直接登出
    if (response.config.url?.includes('refreshToken')) {
      userStore.logOut()
      return
    }

    await userStore.RefreshToken()
    if (response.config.method === 'get') {
      response.config.params = {
        ...response.config.params,
      }
      return await axiosInstance.request(response.config)
    }

    if (response.config.method === 'post') {
      response.config.data = {
        ...JSON.parse(response.config.data),
      }
      return await axiosInstance.request(response.config)
    }
  }

  console.log(`[Axios] Error ${error.status}, ${error.code}, ${errorMessage}`)
  throw error
}

axiosInstance.interceptors.response.use(
  (response) => {
    const debugStore = useDebugStore()
    if (response.headers['request-id']) {
      debugStore.requestIds.push({ time: getCurrentTime(), requestId: response.headers['request-id'] })
    }
    return response.data
  },
  handleError,
)

export function get<T, D = any>(url: string, config?: AxiosRequestConfig<D>) {
  return axiosInstance.get<unknown, T>(url, config)
}

export function post<T, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>) {
  return axiosInstance.post<unknown, T>(url, data, config)
}

export function put<T, D = any>(url: string, data?: D, config?: AxiosConfig) {
  return axiosInstance.put<unknown, T>(url, data, config)
}

export function head<T, D = any>(url: string, config?: AxiosRequestConfig<D>) {
  return axiosInstance.head<unknown, T>(url, config)
}
