<script setup lang="ts">
import { IonApp, IonRouterOutlet, IonToast, useIonRouter } from '@ionic/vue'
import { KeepAwake } from '@capacitor-community/keep-awake'
import { onMounted, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { register } from 'swiper/element/bundle'
import { ConfigProvider } from 'vant'
import type { URLOpenListenerEvent } from '@capacitor/app'
import { App } from '@capacitor/app'
import AuthModal from './components/AuthModal.vue'
import { useAuthStore } from './store/auth'
import { useUserStore } from './store/user'
import { useGlobalStateStore } from './store/globalState'
import FirebaseAnalyticsUtil from './utils/firebaseAnalytics'
import { useToastStore } from '@/store/toast'
import { useNotificationStore } from '@/store/notifications'

const toastStore = useToastStore()
const userStore = useUserStore()
const authStore = useAuthStore()
const globalStore = useGlobalStateStore()
const notificationStore = useNotificationStore()
const { closeModal, handleLogin } = authStore
const { isModalOpened } = storeToRefs(authStore)
const router = useIonRouter()

onMounted(async () => {
  userStore.getAccessTokenFromNative()
  // push the home route if the current route is not /
  register()
  globalStore.initDeviceInfo()
  try {
    const { isSupported } = await KeepAwake.isSupported()
    if (isSupported) {
      await KeepAwake.keepAwake()
    }
  }
  catch (error) {
    console.error(error)
  }
  await notificationStore.registerNotifications()
})

watch(
  () => userStore.tokensAreLoaded,
  (val) => {
    if (val && userStore.isLoggedIn) {
      userStore.getUserInfo()
      FirebaseAnalyticsUtil.setUserId(userStore.user.userId)
    }
  },
)

App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
  console.debug(`AppUrlOpen: ${event.url} got invoked`)

  // example: app.sekai.chat/sekai/1234
  // slug = /sekai/1234
  const slug = event.url.split('.com').pop()

  // We only push to the route if there is a slug present
  if (slug) {
    router.push(slug)
  }
})

// Listen to the App got destroyed
App.addListener('appStateChange', (state) => {
  if (!state.isActive) {
    KeepAwake.allowSleep()
  }
  else {
    KeepAwake.keepAwake()
  }
})
</script>

<template>
  <config-provider theme="dark">
    <ion-app class="font-manrope">
      <auth-modal
        :is-open="isModalOpened"
        :login-state="userStore.loginState"
        @close="closeModal"
        @click:continue-with-apple="handleLogin('apple')"
        @click:continue-with-google="handleLogin('google')"
      />
      <ion-router-outlet
        id="main-content"
      />
      <ion-toast
        :is-open="toastStore.toastIsLoaded"
        :message="toastStore.toast"
        :duration="2000"
        :position="toastStore.toastPosition"
        class="custom-toast"
      />
    </ion-app>
  </config-provider>
</template>

<style>
:root:root {
  --van-steps-background: transparent;
  --van-step-circle-size: 16px;
  --van-step-icon-size: 16px;
  --van-step-font-size: 11px;
  --van-step-horizontal-title-font-size: 11px;
  --van-action-sheet-max-height: 80%;
}

ion-menu {
  --max-width: 100%;
  --width: 100%;
}
</style>
